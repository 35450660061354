/** @jsx jsx */
import { jsx } from "theme-ui";

const P404 = () => (
  <h1
    style={{
      fontSize: "22px",
      fontFamily: "arial",
      textAlign: "center",
      color: "#989898",
      fontWeight: "100",
    }}
  >
    404 - Page Not Found
  </h1>
);

export default P404;
